import React from "react";
import { Link } from "gatsby";
import { useCookies } from "react-cookie";
import * as styles from "../styles/selector.module.css";
import bird from "../assets/bird@2x.png";

const Selector = ({ pageContext }) => {
  const messages = pageContext.messages;
  const [cookies, setCookie] = useCookies([]);
  const updateCookie = (lang) => {
    setCookie("language", lang, { path: "/" });
  };
  return (
    <div className={styles.selectorwrapper}>
      <div className={styles.selectorbody}>
        <div className={styles.birdimg}>
          <img src={bird} alt="bird" />
        </div>
        <div className={styles.selectoroptions}>
          <Link onClick={() => updateCookie("en")} to="/">
            {messages.english}
          </Link>
          <Link onClick={() => updateCookie("hi")} to="/hi">
            {messages.hindi}
          </Link>
          <Link onClick={() => updateCookie("bn")} to="/bn">
            {messages.bengali}
          </Link>
          <Link onClick={() => updateCookie("ta")} to="/ta">
            {messages.tamil}
          </Link>
          <Link onClick={() => updateCookie("ml")} to="/ml">
            {messages.malayalam}
          </Link>
          <Link onClick={() => updateCookie("kn")} to="/kn">
            {messages.kannada}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Selector;
